import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import PrismicRichText from '../atoms/PrismicRichText'
import Cross from '../../assets/svg/cross.inline.svg'

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  color: var(--color-text);
  transition: color 300ms ease-in-out;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: 60%;
  transform: translate(-50%, -50%);
  z-index: 999;
  @media ( min-width: 768px ) {
    width: 50%;
  }
  @media ( min-width: 992px ) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    height: 50%;
  }
`

const ImageHolder = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0px;
    @media ( min-width: 992px ) {
      border-radius: 10px 0px 0px 10px;
    }
  }
`

const ContentHolder = styled.div`
  background-color: var(--color-background);
  transition: background-color 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 0px;
  @media ( min-width: 992px ) {
    border-radius: 0px 10px 10px 0px;
  }

  > div:first-child {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  svg {
    max-width: 75% !important;
    height: auto !important;
  }
`

const ButtonHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :last-child {
    svg {
      height: 2rem !important;
      width: 2rem !important;

      line {
        stroke: var(--color-text);
        transition: stroke 300ms ease-in-out;
      }
    }
  }
`

const Discounts = () => {
    const [show, setShow] = useState(false)
    const [copySuccess, setCopySuccess] = useState('')
    const delay = 10

    const data = useStaticQuery(graphql`
    query {
      prismicPdpExtras(lang: {eq: "en-au"}) {
        data {
          discount_title {
            text
          }
          discount_description {
            richText
          }
          discount_image {
            gatsbyImageData
            alt
          }
          discount_code {
            text
          }
        }
      }
    }
  `)

    const {
        discount_title,
        discount_description,
        discount_image,
        discount_code
    } = data.prismicPdpExtras.data

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe)
            setCopySuccess('Copied!')
        } catch (err) {
            setCopySuccess('Failed to copy!')
        }
    }

    useEffect(
        () => {
            let timer = setTimeout(() => setShow(true), delay * 1000)
            return () => clearTimeout(timer)
        },
        []
    )

    if (!show) return false

    return show && <Holder>
        <ImageHolder>
            <GatsbyImage alt={discount_image.alt} image={discount_image.gatsbyImageData}/>
        </ImageHolder>
        <ContentHolder>
            <div>
                <h1>{discount_title.text}</h1>
                <PrismicRichText render={discount_description.richText}/>
            </div>
            <div>
                <small style={{display: 'block', margin: '0.5rem 0'}}>{copySuccess}</small>
                <ButtonHolder>
                    <button className="button black"
                            onClick={() => copyToClipBoard(discount_code.text)}>{discount_code.text}
                    </button>
                    <button className="button icon" onClick={() => setShow(false)}><Cross/>
                    </button>
                </ButtonHolder>
            </div>
        </ContentHolder>
    </Holder>
}

export default Discounts