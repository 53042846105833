import React from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// Animation duration
const timeout = 300

const TransitionHolder = styled.div`
  position: relative;
`

const PageHolder = styled.div`
  width: 100%;

  &.page-appear,
  &.page-enter {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.page-appear-active,
  &.page-appear-done,
  &.page-enter-active,
  &.page-enter-done {
    opacity: 1;
    transition: opacity ${timeout}ms;
  }

  &.page-exit {
    opacity: 1;
  }

  &.page-exit-active {
    opacity: 0;
    transition: opacity ${timeout}ms;
  }
`

Transition.propTypes = {
    children: PropTypes.any.isRequired
}

function Transition({children, location}) {
    return (
        <TransitionHolder>
            <TransitionGroup>
                <CSSTransition
                    key={location.pathname}
                    timeout={timeout}
                    classNames="page"
                >
                    <PageHolder>{children}</PageHolder>
                </CSSTransition>
            </TransitionGroup>
        </TransitionHolder>
    )
}

export default Transition