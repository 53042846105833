import React from 'react'
import PropTypes from 'prop-types'
import Transition from '../components/atoms/Transition'
import GlobalStyles from '../components/atoms/GlobalStyles'
import {ThemeProvider} from 'styled-components'
import {theme} from '../utils/styling'
import LoadingHolder from '../components/atoms/LoadingHolder'
import Discounts from '../components/organisms/Discounts'

function Index({head, foot, location, children}) {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <LoadingHolder path={location.pathname}/>
            <Discounts/>
            {head}
            <Transition location={location}>
                {children}
            </Transition>
            {foot}
        </ThemeProvider>
    )
}

Index.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Index
