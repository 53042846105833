import {createGlobalStyle} from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    ${(props) => props.theme.type.fluidType(0)};

    * {
      box-sizing: border-box;
    }
  }

  body {
    margin: 0;
    // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
    font-family: "Circular Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background: var(--color-background);
    color: var(--color-text);
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  }

  svg {
    path {
      fill: var(--color-text);
      transition: fill 300ms ease-in-out;
    }
  }

  p,
  ol, ul, li,
  code, kbd, pre, samp {
    line-height: 1.5;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Circular Light";
    letter-spacing: 0.01rem;
    line-height: 1.15;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: normal;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }
  }

  h1, .h1 {
    ${(props) => props.theme.type.fluidType(4)};
  }

  h2, .h2 {
    ${(props) => props.theme.type.fluidType(3)};
  }

  h3, .h3 {
    ${(props) => props.theme.type.fluidType(2)};
  }

  h4, .h4 {
    ${(props) => props.theme.type.fluidType(1)};
  }

  h5, .h5 {
    ${(props) => props.theme.type.fluidType(0)};
  }

  h6, .h6 {
    ${(props) => props.theme.type.fluidType(-1)};
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small {
    ${(props) => props.theme.type.fluidType(-2)};
  }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ul {
    padding-left: 0rem;
    list-style-type: none;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  // Specific to PrismicRichText component
  .block-img {
    display: inline-block;
    max-width: 10rem;
    max-height: 10rem;
    overflow: hidden;
    display: flex;

    img {
      width: 100%;
      height: auto;
      display: inline-block;
      margin: 1.5rem 0 0rem 0;
    }
  }

  // Specific to PrismicRichText component
  .embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 3rem 0;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  em, i {
    font-style: italic;
  }

  strong, b {
    font-family: "Circular Regular";
  }

  blockquote {
    font-family: "Circular Regular";
    padding-left: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub {
    top: 0.4em;
  }

  label {
    ${(props) => props.theme.type.fluidType(0)};
    line-height: 1.2;
    font-weight: normal;

    &.radio-button, &.checkbox-button {
      display: grid;
      align-items: center;
      grid-template-columns: 1rem auto;
      gap: 0.75em;
      margin-bottom: 0.5em;
      cursor: pointer;
    }

    &.none {
      display: none;
    }
  }

  .fieldset {
    margin: 0.5rem 0;
    width: 100%;
    position: relative;
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    display: block;
    font-family: inherit;
    ${(props) => props.theme.type.fluidType(0)};
    color: var(--color-text);
    transition: all 300ms ease-in-out;
    padding: 0.2rem 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 1px solid;
    border-radius: 2px;
    line-height: 1.6;
    background-color: transparent;
    -webkit-appearance: none;

    :focus {
      outline: none;
    }

    &.fullWidth {
      width: 100%;
    }

    &.noBorder {
      border: none;
      margin: 0.5rem 0;
    }

    &.grey {
      background-color: var(--color-secondary);
      border: none;
      padding: 0.5rem;
    }

    &.underline {
      border: none;
      border-bottom: 1px solid var(--color-text);
      border-radius: 0px;

      svg {
        height: 1.5rem;
        width: auto;
        display: block;
      }
    }
  }

  ::placeholder {
    color: var(--color-text);
    opacity: 0.6;
    transition: all 300ms ease-in-out;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0.25rem 0;
    padding: 0;
    color: var(--color-text);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--color-text);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    transition: all 300ms ease-in-out;

    &:checked {
      background-color: var(--color-text);
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.25rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: var(--color-text);
    -webkit-transition: 300ms;
    transition: 300ms;

    &:before {
      position: absolute;
      content: "";
      width: 1rem;
      height: 1rem;
      left: 0.125rem;
      bottom: 0.125rem;
      background-color: var(--color-background);
      -webkit-transition: 300ms;
      transition: 300ms;
      border-radius: 50%;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.25rem);
    -ms-transform: translateX(1.25rem);
    transform: translateX(1.25rem);
  }

  // Select

  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: var(--color-text);
    transition: all 300ms ease-in-out;

    &.border {
      border: 1px solid var(--color-sheratoncream);
      padding: 0.25rem 1rem;
      border-radius: 2px;
      line-height: 2;
      cursor: pointer;
      display: inline-block;
    }
  }

  select::-ms-expand {
    display: none;
  }

  .fieldset.select {
    border-radius: 0;
    padding: 0.5rem;
    margin: 0;
    ${props => props.theme.type.fluidType(0)};
    cursor: pointer;
    line-height: 1.6;
    background-color: var(--color-secondary);
    grid-template-areas: " select ";
    display: grid;
    align-items: center;
    transition: all 300ms ease-in-out;

    &:after {
      content: "";
      width: 0.8rem;
      height: 0.5rem;
      background-color: #1E1E1E;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      justify-self: end;
    }

    select,
    &:after {
      grid-area: select;
    }
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;
    padding: 0.25rem 1rem;
    font-family: inherit;
    ${(props) => props.theme.type.fluidType(0)};
    line-height: 2;
    text-decoration: none;
    white-space: nowrap;
    width: max-content;
    height: max-content;
    border: 1px solid var(--color-sheratoncream);
    border-radius: 2px;
    color: var(--color-text);
    background-color: transparent;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: var(--color-secondary);
    }

    &.active {
      background-color: var(--color-secondary);
    }

    &.black {
      background-color: var(--color-text);
      color: var(--color-background);

      &:hover {
        background-color: var(--color-background);
        color: var(--color-text);
      }
    }

    &:disabled {
      cursor: auto;
      background-color: #919191;

      &:hover {
        background-color: #919191;
        color: #FFFFFF;
      }
    }

    &.fullWidth {
      width: 100%;
      text-align: center;
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
    }

    &.underline {
      border: none;
      text-decoration: underline;
      font-family: 'Rufina', serif;
      padding: 0;
      background-color: transparent;
    }

    &.form-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 100%;
    }

    &.text-icon {
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: transparent;

      svg {
        height: 1rem;
        width: auto;
        display: block;

        path {
          fill: var(--color-text);
          transition: fill 300ms ease-in-out;
        }
      }
    }

    &.icon {
      display: flex;
      align-items: center;
      gap: 1rem;
      border: none;
      padding: 0;
      background-color: transparent;

      svg {
        height: 1rem;
        width: auto;
        display: block;

        path {
          fill: var(--color-text);
          transition: fill 300ms ease-in-out;
        }
      }
    }
  }

  .product-count {
    color: #919191;
  }

  .errorMsg {
    color: var(--color-text);
    transition: color 300ms ease-in-out;
    display: block;
    margin: 0.8rem 0;
  }

  .relative {
    position: relative;
  }

  .carousel-cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 250;
    direction: ltr;
    contain: layout style size;
    pointer-events: none;
    transition: opacity 0.3s, color 0.4s;

    &:before {
      content: '';
      position: absolute;
      top: -2rem;
      left: -2rem;
      display: block;
      width: 4rem;
      height: 4rem;
      transform: scale(0.2);
      ${(props) => props.theme.type.fluidType(-4)};
      background-color: var(--color-primary);
      border-radius: 50%;
      transition: transform 0.25s ease-in-out, opacity 0.1s, background-color 0.25s ease-in-out;
      opacity: 0.5;
    }

    &.grab {
      &:before {
        transform: scale(2);
      }
    }

    &.icon {
      &:before {
        content: 'Drag Me';
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &.hover {
      &:before {
        transform: scale(0);
      }
    }

    &.hidden {
      &:before {
        transform: scale(0);
      }
    }
  }
`

export default GlobalStyle
