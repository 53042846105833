export const theme = {
    colours: {
        black100: '#000000',
        black90: '#1E1E1E',
        black80: '#323232',
        black70: '#4F4F4F',
        black60: '#919191',
        black50: '#DDDEE2',
        black40: '#F0F0F0',
        white: '#FFFFFF',
        sheratongrey: '#333536',
        sheratoncream: '#EBE5E5',
        sheratongold: '#AF986E',
        background: '#FFFFFF',
        primary: '#EBE5E5',
        secondary: '#F0F0F0',
        copy: '#000000',
    },
    typography: {
        min: 12,
        max: 16,
        minScreen: 400,
        maxScreen: 1200,
        scale: {
            min: 1.05,
            max: 1.15,
        },
    },
    type: {
        // https://www.smashingmagazine.com/2016/05/fluid-typography/
        fluidType: (exp) => {
            return `
      font-size: ${
                theme.typography.min * Math.pow(theme.typography.scale.min, exp)
            }px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${
                theme.typography.min * Math.pow(theme.typography.scale.min, exp)
            }px + (${
                theme.typography.max * Math.pow(theme.typography.scale.max, exp)
            } - ${
                theme.typography.min * Math.pow(theme.typography.scale.min, exp)
            })*(100vw - ${theme.typography.minScreen}px)/(${
                theme.typography.maxScreen
            } - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${
                theme.typography.max * Math.pow(theme.typography.scale.max, exp)
            }px;
      }
      `
        },
    },
    breakpoints: {
        sm: 'min-width: 576px',
        md: 'min-width: 768px',
        lg: 'min-width: 992px',
        xl: `min-width: 1200px`,
    },
}