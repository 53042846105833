import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import GsapLoader from './GsapLoader'

function LoadingHolder({path}) {
    const [loading, setLoading] = useState(true)
    const timeout = 6000

    useEffect(() => {
        setTimeout(() => setLoading(false), timeout)
    }, [])

    if (path !== '/' || loading === false) return null

    return <GsapLoader/>
}

LoadingHolder.propTypes = {
    path: PropTypes.string.isRequired,
}

export default LoadingHolder