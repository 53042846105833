const {defaultLanguage} = require('../../prismic-configuration')

exports.linkResolver = (doc) => {
    switch (doc.type) {
        case 'homepage': {
            return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`
        }

        case 'showroom': {
            return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
        }

        case 'misc': {
            return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
        }

        case 'about': {
            return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
        }

        case 'faq': {
            return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
        }

        case 'review_index': {
            return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`
        }

        default:
            return '/'
    }
}
