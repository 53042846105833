import React, {useEffect} from 'react'
import styled from 'styled-components'
import {gsap, Power3, Power4} from 'gsap'
import Logo from '../../assets/svg/logotype.inline.svg'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'

const Holder = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  transition: all 300ms ease;
  background-color: var(--color-background);
  z-index: 20;
  opacity: 1;
`

const Inner = styled.div`
  position: relative;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1rem;
    width: auto;
    transform: translate(-50%, -50%);
    transition: all 300ms ease;

    .sheraton, .luxury {
      fill: var(--color-text);
      visibility: hidden;
    }
  }
`

function GsapLoader() {
    useLockBodyScroll()

    useEffect(() => {
        let tl = gsap.timeline({defaults: {autoAlpha: 1}})
        tl.fromTo('.sheraton', {duration: 1, yPercent: 300, ease: Power3.easeOut},
            {delay: 1, duration: 1, yPercent: 0, ease: Power3.easeOut})
            .from('.luxury', {duration: 1, yPercent: 300, opacity: 0, autoAlpha: 0, ease: Power4.easeOut})
            .to(['.loading-background', '.sheraton', '.luxury'], {
                delay: 2,
                duration: 1,
                opacity: 0,
                autoAlpha: 0,
                ease: Power3.easeOut
            })
    })

    return (
        <Holder className="loading-background">
            <Inner>
                <Logo/>
            </Inner>
        </Holder>
    )
}

export default GsapLoader